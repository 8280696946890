<template>
  <div class="container">
    <div v-if="JSON.stringify(obj)!=='{}'">
      <b-card
        header="編輯主辦單位"
        header-border-variant="white"
        header-bg-variant="light"
        footer-bg-variant="light"
        body-border-variant="white"
        align="center"
        class="card-3"
      >
        <b-card-body>
          <div
            id="style-3"
            style="height:580px;padding:5px 0px 5px 0px;overflow-x:hidden;overflow-y:visible;"
          >
            <div class="row">
              <p class="h6">
                <b-icon-card-image></b-icon-card-image>圖貼
                <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
              </p>
              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
              <div
                class="col"
                style="text-align:center; display: flex;height: 280px;align-items: center;"
              >
                <a-card hoverable style="width: 226px;height: 280px;margin-right: 10px;">
                <img
                  slot="cover"
                  alt="example"
                  :src="form.prourl"
                  @click="changimg('0', 150 , 150 ,'頭貼')"
                  id="headid"
                  shape="square"
                  class="img-thumbnail"
                  style="cursor: pointer;"
                />
                <a-card-meta title="頭貼 : 上傳尺寸 150x150">
                </a-card-meta>
              </a-card>
                <a-card hoverable style="height: 280px;" >
                <img
                  slot="cover"
                  alt="example"
                  :src="form.titurl? form.titurl:catImg('myinfor.png')"
                  id="headid2"
                  @click="changimg('1',1080,370,'Title Bar')"
                  class="frams"
                  style="cursor: pointer;"
                />
                <a-card-meta title="Title Bar : 上傳尺寸 1080x370">
                </a-card-meta>
              </a-card>

                
              </div>
            </div>
            <p></p>
            <b-form @submit="onSubmit" @reset="onReset">
              <div class="row">
                <p class="h6">
                  <b-icon-link></b-icon-link>關於
                </p>
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-11"
                    style="width:350px;"
                    label="建立上層關聯*"
                    label-for="input-11"
                  >
                    <b-form-select
                      v-model="form.ntypeid"
                      :state="ntypeState"
                      :disabled="form.state=='Y'"
                      id="radio-group-11"
                      class="mb-3"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- 請選擇 --</b-form-select-option>
                        <b-form-select-option
                          v-for="(result,i) in orgdata"
                          :key="i"
                          :value="result.id"
                        >{{result.name}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-1"
                    style="width:350px"
                    label="主辦單位名稱*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.name"
                      type="text"
                      placeholder="輸入主辦單位名稱 "
                      :state="nameState"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-2"
                    style="width:520px"
                    label="主辦單位簡介*  "
                    label-for="input-2"
                  >
                    <b-form-textarea
                      id="input-2"
                      v-model="form.desc"
                      type="text"
                      placeholder="輸入主辦單位簡介 "
                      rows="8"
                      autocomplete="off"
                      :state="descState"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <p class="h6">
                  <b-icon-grid-fill></b-icon-grid-fill>聯絡資料
                </p>
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />

                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-3"
                    style="width:350px"
                    label="主辦單位 Email*"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      v-model="form.email"
                      type="email"
                      placeholder="輸入主辦單位 Email "
                      autocomplete="off"
                      :state="emailState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-4"
                    style="width:230px"
                    label="主辦單位電話號碼*  "
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      v-model="form.hphone"
                      type="number"
                      placeholder="輸入主辦單位,電話號碼 "
                      autocomplete="off"
                      :state="hphoneState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-5"
                    style="width:230px"
                    label="主辦單位手機號碼*  "
                    label-for="input-5"
                  >
                    <b-form-input
                      id="input-5"
                      v-model="form.mphone"
                      type="number"
                      placeholder="輸入主辦單位, 手機 "
                      autocomplete="off"
                      :state="mphoneState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col" style="text-align:left">
                  <Addresscom @toaddress="toaddress" />
                  <b-form-group
                    id="input-group-6"
                    style="width:540px"
                    label="主辦單位聯絡地址*  "
                    label-for="input-6"
                  >
                    <b-form-input
                      id="input-6"
                      v-model="form.address"
                      type="text"
                      placeholder="輸入主辦單位聯絡地址"
                      autocomplete="off"
                      :state="addressState"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <p class="h6">
                  <b-icon-tree-fill></b-icon-tree-fill>媒體連結
                </p>
                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;" />
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-7"
                    style="width:300px"
                    label="官方網站  "
                    label-for="input-7"
                  >
                    <b-form-input
                      id="input-7"
                      v-model="form.sinkurl"
                      type="url"
                      placeholder="輸入官方網站"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-8"
                    style="width:300px"
                    label="Facebook  "
                    label-for="input-8"
                  >
                    <b-form-input
                      id="input-8"
                      v-model="form.finkurl"
                      type="url"
                      placeholder="輸入Facebook"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-9"
                    style="width:300px"
                    label="Instagram  "
                    label-for="input-9"
                  >
                    <b-form-input
                      id="input-9"
                      v-model="form.iinkurl"
                      type="url"
                      placeholder="輸入Instagram"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col" style="text-align:left">
                  <b-form-group
                    id="input-group-10"
                    style="width:300px"
                    label="Youtube  "
                    label-for="input-10"
                  >
                    <b-form-input
                      id="input-10"
                      v-model="form.yinkurl"
                      type="url"
                      placeholder="輸入Youtube"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </b-card-body>
        <template #footer>
          <small class="text-muted">
            <a-button type="dashed" @click="save()" :loading="loading">( 存 檔 )</a-button>
          </small>
        </template>
      </b-card>
    </div>
    <b-modal
      ref="my-loading"
      id="modal-2"
      title="Loading"
      @hide="onHide"
      centered
      hide-footer
      hide-header
    >
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <strong>Loading....</strong>
      </div>
    </b-modal>
    <div class="divhidden">
      <a-upload
        ref="myBtn"
        name="imgfile"
        list-type="picture"
        class="avatar-uploader"
        method="post"
        :show-upload-list="false"
        :headers="headers"
        :action="uploadurl"
        :before-upload="beforeUpload"
        @change="handleChange"
      ></a-upload>
    </div>
    <b-alert
      v-model="showTop"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible
    >更新成功</b-alert>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { makorgService } from "../../_services";
import Addresscom from "../../components/Addresslist.vue";

const putorgdata = obj => {
  return makorgService.putorgdata(obj);
};

export default {
  name: "Editorg",
  components: {
    Addresscom
  },
  data() {
    return {
      uploadurl: process.env.VUE_APP_URL + "user/image",
      orgdata: [],
      obj: {},
      showTop: false,
      loading: false,
      errors:"",
      sizeMsg:{
        width:0,
        height:0,
        msg:''
      },
      mtyps:"warning",
      form: {
        id: "",
        name: "",
        type: "",
        ntype: "",
        ntypeid: "",
        state: "",
        desc: "",
        cid: "0",
        address: "",
        email: "",
        hphone: "",
        mphone: "",
        titurl: "",
        prourl: "",
        sinkurl: "",
        yinkurl: "",
        iinkurl: "",
        finkurl: ""
      }
    };
  },
  mounted() {
    this.obj = this.datas.find(item => item.id == this.defaultval.id);
    this.inid();
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"]),
    ntypeState() {
      return this.form.ntype !== "" ? true : false;
    },
    nameState() {
      return this.form.name.length > 0 ? true : false;
    },
    descState() {
      return this.form.desc.length > 0 ? true : false;
    },
    emailState() {
      return this.form.email.length > 0 ? true : false;
    },
    hphoneState() {
      return this.form.hphone.length > 0 ? true : false;
    },

    mphoneState() {
      return this.form.mphone.length > 0 ? true : false;
    },

    addressState() {
      return this.form.address.length > 0 ? true : false;
    },

    headers() {
      return {
        "X-Requested-With": null,
        Authorization: "Bearer " + this.status.token
      };
    }
  },
  watch: {
    defaultval(val) {
      this.obj = this.datas.find(item => item.id == val.id);
      this.inid();
    }
  },
  methods: {
    ...mapActions("makeorg", ["readorgdata", "insertorg"]),
    async inid() {
      this.form.id = this.obj.id;
      this.form.name = this.obj.name;
      this.form.type = this.obj.type;
      this.form.ntype = this.obj.ntype;
      this.form.ntypeid = this.obj.ntypeid;
      this.form.state = this.obj.state;
      this.form.desc = this.obj.desc;
      this.form.address = this.obj.address;
      this.form.email = this.obj.email;
      this.form.hphone = this.obj.hphone;
      this.form.mphone = this.obj.mphone;
      this.form.titurl = this.obj.titurl;
      this.form.prourl = this.obj.prourl;
      this.form.sinkurl = this.obj.Sinkurl;
      this.form.yinkurl = this.obj.Yinkurl;
      this.form.iinkurl = this.obj.Iinkurl;
      this.form.finkurl = this.obj.Finkurl;

      if (this.form.ntype.length > 1) {
        this.orgdata = [];
        let obj = { typname: this.form.ntype };
        this.orgdata = await this.readorgdata(obj);

      }
    },

    save() {
      this.datas.find(
        item => item.id == this.defaultval.id
      ).name = this.form.name;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).ntypeid = this.form.ntypeid;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).desc = this.form.desc;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).address = this.form.address;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).email = this.form.email;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).hphone = this.form.hphone;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).mphone = this.form.mphone;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).Sinkurl = this.form.sinkurl;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).Yinkurl = this.form.yinkurl;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).Iinkurl = this.form.iinkurl;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).Finkurl = this.form.finkurl;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).prourl = this.form.prourl;
      this.datas.find(
        item => item.id == this.defaultval.id
      ).titurl = this.form.titurl;
      let tmp = this.datas.find(item => item.id == this.defaultval.id);
      this.loading = true;
      this.$refs['my-loading'].show()
      putorgdata(tmp).then(({ data }) => {
        if (data.code == 200) {
          this.showTop = true;
          setTimeout(() => {
            this.$refs['my-loading'].hide()
            this.loading = false;
            this.showTop = false;
            clearInterval();
          }, 2000);
        }
      });
    },

    async onSubmit(event) {
      event.preventDefault();
    },
    onReset(event) {
      event.preventDefault();
      this.$nextTick(() => {});
    },
    toaddress(val,city,village,town) {
      this.form.address = val;
      console.log(city,village,town) 
    },
    async beforeUpload(file) {
      return new Promise((resolve, reject) => {
        this.errors = "";
        //this.$emit('upmessage',null)
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
          const isLt2M = file.size / 1024 / 1024 < 3;
          if (img.width !== this.sizeMsg.width || img.height !== this.sizeMsg.height) {
            this.errors = "您上傳的"+this.sizeMsg.msg+"圖片尺寸不符合 : "+this.sizeMsg.width +'x'+this.sizeMsg.height+" pixel !";
            this.mtyps = "error";
            reject(file);
          } else if (!isJpgOrPng) {
            //this.$message.error('您的檔案必須是 JPG or PNG!');
            this.errors = "您的檔案必須是 JPG or PNG!";
            this.mtyps = "error";
            //alert('您的檔案必須是 JPG or PNG!')
            //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
            reject(file);
          } else if (!isLt2M) {
            //this.$message.error('您的檔案必須是小於 2MB!');
            this.errors = "您的檔案必須是小於 2MB!";
            this.mtyps = "error";
            //alert('您的檔案必須是小於 3MB!')
            //this.$emit('upmessage','您的檔案必須是小於 2MB!')
            reject(file);
          } else {
            this.$refs['my-loading'].show()
            resolve(file);
          }
        };
      });
    },

    async handleChange({ file }) {
      if (file.status === "done") {
        let xurl = file.response.Location;
        if (this.cid == "0") {
          this.form.prourl = xurl;
        } else if (this.cid == "1") {
          this.form.titurl = xurl;
        }

        this.$refs["my-loading"].hide();
      }
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        //this.handleBackdrop();
      }
    },

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    changimg(val,va2,va3,va4) {
      this.sizeMsg.width = va2 
      this.sizeMsg.height = va3   
      this.sizeMsg.msg = va4   
      this.cid = val;
      this.upload();
    },

    upload() {
      this.$refs.myBtn.$refs.uploadRef.$el.firstChild.click();
    }
  }
};
</script>
<style>
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #535151;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #535151;
}
.divhidden {
  visibility: hidden;
}
.frams {
  cursor: pointer;
  width: 685px;
  height: 230px;
  background-color: #bdcbe2;
  border-style: solid;
  border-width: 1px;
  border-color: #d3d2d2;
}
</style>